import * as yup from "yup";

const emailRegExp = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+$/;

const validationSchema = {
	loginValidationSchema: yup.object({
		email: yup
			.string()
			.email("Enter a valid email")
			.max(60, "Email address must not be more than 100 characters")
			.matches(emailRegExp, "Enter a valid email")
			.required("Email is required"),
		password: yup
			.string()
			.min(8, "Password should be of minimum 8 characters length")
			.required("Password is required"),
	}),

	changePasswordValidationSchema: yup.object({
		currentPassword: yup
			.string()
			.min(8, "Password should be of minimum 8 characters length")
			.required("Field cannot be empty"),
		newPassword: yup
			.string()
			.min(8, "Password should be of minimum 8 characters length")
			.required("Field cannot be empty"),
		confirmPassword: yup
			.string()
			.min(8, "Password should be of minimum 8 characters length")
			.required("Field cannot be empty")
			.oneOf([yup.ref("newPassword"), null], "New passwords does not match."),
	}),

	myProfileValidationSchema: yup.object({
		name: yup
			.string()
			.max(70, "Name must not be more than 70 characters")
			.required("Name is required"),
		email: yup
			.string()
			.email("Enter a valid email")
			.max(60, "Email address must not be more than 100 characters")
			.matches(emailRegExp, "Enter a valid email")
			.required("Email is required"),
	}),

	makeRequestValidationSchema: yup.object().shape({
		email: yup
			.string()
			.email("Enter a valid email")
			.max(60, "Email address must not be more than 60 characters")
			.matches(emailRegExp, "Enter a valid email"),
		cc: yup
			.array()
			.transform(function (value, originalValue) {
				if (this.isType(value) && value !== null) {
					return value;
				}
				return originalValue ? originalValue.split(/[\s,]+/) : [];
			})
			.of(
				yup
					.string()
					.max(70, "Email cannot be more than 60 characters")
					.email("Enter a valid email")
					.matches(emailRegExp, "Enter a valid email")
			),
		referenceNo: yup
			.string()
			.max(60, "Reference number must not be more than 60 characters")
			.nullable()
			.required("Reference number is required"),
		jobTypeId: yup.number().required("Please select Job Type"),
		quantity: yup
			.number()
			.nullable()
			.when("jobTypeLabel", {
				is: (value) => value === "Empty",
				then: yup.number().required("Please enter quantity"),
			}),
		cargoTypeId: yup
			.string()
			.nullable()
			.when("jobTypeLabel", {
				is: (value) => ["Import", "Export", "Temp Control"].includes(value),
				then: yup.string().required("Please select cargo type"),
			}),
		dropOffLocation: yup
			.string()
			.max(255, "Dropoff location must not be more than 255 characters")
			.when(["jobTypeLabel", "radioType"], {
				is: (value, val) =>
					value !== "Export" && !["dropOff", ""].includes(val),
				then: yup.string().required("Drop off location is required"),
			}),
		requesterName: yup
			.string()
			.nullable()
			.max(70, "Requester name must not be more than 70 characters"),
		description: yup
			.string()
			.nullable()
			.max(500, "Description must not be more than 500 characters"),
		dropOffLongitude: yup.string().when(["jobTypeLabel", "radioType"], {
			is: (value, val) => value !== "Export" && !["dropOff", ""].includes(val),
			then: yup.string().required("Invalid Address"),
		}),
		ctoId: yup
			.string()
			.nullable()
			.when("jobTypeLabel", {
				is: (value) => ["Import", "Export", "Empty"].includes(value),
				then: yup.string().required("Please select CTO"),
			}),
		pickUpLocation: yup
			.string()
			.max(255, "Pickup location must not be more than 255 characters")
			.when(["jobTypeLabel", "radioType"], {
				is: (value, val) => value !== "Import" && !["pickUp", ""].includes(val),
				then: yup.string().required("Pick up location is required"),
			}),
		pickUpLongitude: yup.string().when(["jobTypeLabel", "radioType"], {
			is: (value, val) => value !== "Import" && !["pickUp", ""].includes(val),
			then: yup.string().required("Invalid Address"),
		}),
	}),

	addAirWayBillValidationSchema: yup.object({
		number: yup
			.string()
			.test(
				"len",
				"Must be exactly 8 or 11 characters",
				(val) => val?.length === 9 || val?.length === 12
			)
			.required("AWB number is required"),
		// https://wymap.atlassian.net/browse/MAPTRAK-1051 make flight optional
		flight: yup
			.string()
			.nullable()
			.max(50, "Airline must not be more than 50 characters"),
		// .required("Airline is required"),
		weight: yup
			.number()
			.positive("Total weight must be greater than 0")
			.typeError("Total weight must be a number")
			.required("Total weight is required")
			.max(99999, "Total weight  must not be more than 99999"),
		quantity: yup
			.number()
			.positive("Total quantity must be greater than 0")
			.required("Total quantity is required")
			.max(99999, "Total quantity  must not be more than 99999"),
		dangerousGood: yup.boolean(),
		dgClassification: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.string()
					.required("DG Classifications is required")
					.max(50, "DG Classification must not be more than 50 characters"),
			}),
		unNumber: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("UN Number is required")
					.max(99999, "UN Number must not be more than 99999")
					.positive("UN Number must be greater than 0")
					.typeError("UN Number must be a number"),
			}),
		volume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required(
						"Total weight or volume being carried per vehicle is required"
					)
					.max(99999, "Weight/Volume must not be more than 99999")
					.positive("Weight/Volume must be greater than 0")
					.typeError("Weight/Volume must be a number"),
			}),
		indVolume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("Individual(inner) packaging weight or volume is required")
					.max(99999, "Individual Weight/Volume must not be more than 99999")
					.positive("Individual Weight/Volume must be greater than 0")
					.typeError("Individual Weight/Volume must be a number"),
			}),
		rePaperWork: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup.string().required("please enter Yes or No"),
			}),
		readyDate: yup.date().nullable().typeError("Invalid Date"),
	}),

	addConsignmentValidationSchema: yup.object({
		number: yup
			.string()
			.max(50, "Consignment number must not be more than 50 characters")
			.required("Consignment number is required"),
		weight: yup
			.number()
			.max(99999, "Weight must not be more than 99999")
			.positive("Weight must be greater than 0")
			.typeError("Weight must be a number")
			.required("Weight is required"),
		quantity: yup
			.number()
			.max(99999, "Quantity must not be more than 99999")
			.positive("Quantity must be greater than 0")
			.required("Quantity is required"),
		photo: yup
			.mixed()
			.test(
				"fileSize",
				"File Size is too large, File size must be 5MB or below 5MB",
				(value) => !value || (value && value.size <= 5242880)
			),
		dangerousGood: yup.boolean(),
		dgClassification: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.string()
					.required("DG Classifications is required")
					.max(50, "DG Classification must not be more than 50 characters"),
			}),
		unNumber: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("UN Number is required")
					.max(99999, "UN Number must not be more than 99999")
					.positive("UN Number must be greater than 0")
					.typeError("UN Number must be a number"),
			}),
		volume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required(
						"Total weight or volume being carried per vehicle is required"
					)
					.max(99999, "Weight/Volume must not be more than 99999")
					.positive("Weight/Volume must be greater than 0")
					.typeError("Weight/Volume must be a number"),
			}),
		indVolume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("Individual(inner) packaging weight or volume is required")
					.max(99999, "Individual Weight/Volume must not be more than 99999")
					.positive("Individual Weight/Volume must be greater than 0")
					.typeError("Individual Weight/Volume must be a number"),
			}),
		rePaperWork: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup.string().required("please enter Yes or No"),
			}),
	}),
	editConsignmentValidationSchema: yup.object({
		number: yup
			.string()
			.max(50, "Consignment number must not be more than 50 characters")
			.required("Consignment number is required"),
		weight: yup
			.number()
			.max(99999, "Weight must not be more than 99999")
			.positive("Weight must be greater than 0")
			.typeError("Weight must be a number")
			.required("Weight is required"),
		quantity: yup
			.number()
			.max(99999, "Quantity must not be more than 99999")
			.positive("Quantity must be greater than 0")
			.required("Quantity is required"),
		photo: yup
			.mixed()
			.test(
				"fileSize",
				"File Size is too large, File size must be 5MB or below 5MB",
				(value) => !value || (value && value.size <= 5242880)
			),
		dangerousGood: yup.boolean(),
		dgClassification: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.string()
					.required("DG Classifications is required")
					.max(50, "DG Classification must not be more than 50 characters"),
			}),
		unNumber: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("UN Number is required")
					.max(99999, "UN Number must not be more than 99999")
					.positive("UN Number must be greater than 0")
					.typeError("UN Number must be a number"),
			}),
		volume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required(
						"Total weight or volume being carried per vehicle is required"
					)
					.max(99999, "Weight/Volume must not be more than 99999")
					.positive("Weight/Volume must be greater than 0")
					.typeError("Weight/Volume must be a number"),
			}),
		indVolume: yup
			.number()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup
					.number()
					.required("Individual(inner) packaging weight or volume is required")
					.max(99999, "Individual Weight/Volume must not be more than 99999")
					.positive("Individual Weight/Volume must be greater than 0")
					.typeError("Individual Weight/Volume must be a number"),
			}),
		rePaperWork: yup
			.string()
			.nullable()
			.when("dangerousGood", {
				is: true,
				then: yup.string().required("please enter Yes or No"),
			}),
	}),

	addConsignmentItemValidationSchema: yup.object({
		additionalInfo: yup
			.string()
			.nullable()
			.max(255, "Additional info must not be more than 255 characters"),
		quantity: yup
			.number()
			.max(99999, "Quantity must not be more than 99999")
			.positive("Quantity must be greater than 0")
			.required("Quantity is required"),
		photo: yup
			.mixed()
			.test(
				"fileSize",
				"File Size is too large, File size must be 5MB or below 5MB",
				(value) => !value || (value && value.size <= 5242880)
			),
	}),

	editConsignmentItemValidationSchema: yup.object({
		additionalInfo: yup
			.string()
			.nullable()
			.max(255, "Additional info must not be more than 255 characters"),
		quantity: yup
			.number()
			.max(99999, "Quantity must not be more than 99999")
			.positive("Quantity must be greater than 0")
			.required("Quantity is required"),
		photo: yup
			.mixed()
			.test(
				"fileSize",
				"File Size is too large, File size must be 5MB or below 5MB",
				(value) => !value || (value && value.size <= 5242880)
			),
	}),
	addULDValidationSchema: yup.object().shape({
		volume: yup
			.number()
			.nullable()
			.typeError("Volume must be number")
			.positive("Volume must be greater than 0")
			.max(99999, "Volume must not be more than 99999"),
		uldNumber: yup
			.string()
			.required("Uld number  is required")
			.matches(
				/^[A-Za-z]{3}[0-9]{4,5}[A-Za-z0-9]{2,3}$/,
				"Enter valid uld number"
			),
		quantity: yup
			.number()
			.positive("Quantity must be greater than 0")
			.max(99999, "Quantity must not be more than 99999")
			.required("Quantity is required"),
	}),
	addLooseValidationSchema: yup.object({
		quantity: yup
			.number()
			.max(99999, "Quantity must not be more than 99999")
			.positive("Quantity must be greater than 0")
			.required("Quantity is required"),
	}),

	selectParentValidationSchema: yup.object({
		parentId: yup
			.string()
			.nullable()
			.required("Please select parent customer")
	}),
};

export default validationSchema;
